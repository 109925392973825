import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import NavigationWrapper from '../../containers/navigation/navigation-wrapper'
import NavigationSettings from '../../containers/navigation/navigation-settings'
import LoadingOverlay from '../../containers/loading-overlay'
import AsyncNavigationFooter from '../../containers/navigation/async-navigation-footer'
import useSearchParams from '../hooks/useSearchParams'
import ContinuityProvider from '../../modules/continuity/hooks/continuity-context'
import { PagedSearchResult } from '../../modules/search/components/paged-search-result/paged-search-result'

import PinCheck from '../../containers/pin/pin-check'
import styles from './my-account-content.css'

// eslint-disable-next-line react/prop-types
const Content = ({
  children,
  hasSearchParams
}) => {
  if (hasSearchParams) {
    return <PagedSearchResult />
  }
  return (
    <>
      <div className={styles.navigation}>
        <NavigationSettings />
      </div>
      <div className={styles.mainPanel}>{children}</div>
    </>
  )
}

Content.propTypes = {
  hasSearchParams: PropTypes.bool.isRequired
}

const MyAccountContent = ({ children }) => {
  const { hasSearchParams, location } = useSearchParams()

  return (
    <>
      <PinCheck />
      <LoadingOverlay />
      <NavigationWrapper>
        <ContinuityProvider>
          <main className={classNames(styles.searchContent, {
            [styles.content]: !hasSearchParams
          })}
          >
            <Content
              hasSearchParams={hasSearchParams}
            >
              {children}
            </Content>
          </main>
        </ContinuityProvider>
      </NavigationWrapper>
      {hasSearchParams
        ? <AsyncNavigationFooter location={location} />
        : null}
    </>
  )
}

export default MyAccountContent
