import React from 'react'
import styles from './my-account-wrapper.css'
import NavigationHeader from '../../containers/navigation/navigation-header'
import NavigationSide from '../../containers/navigation/navigation-side'
import UseNotFoundProvider from '../../components/not-found/hooks/use-not-found-context'

const MyAccountWrapper = ({ children }) => {
  return (
    <UseNotFoundProvider>
      <div className={styles.layout}>
        <NavigationHeader isMyAccountLayout />
        <NavigationSide />
        {children}
      </div>
    </UseNotFoundProvider>
  )
}

export default MyAccountWrapper
