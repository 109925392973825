import React from 'react'
import PropTypes from 'prop-types'
import useSearchParams from '../hooks/useSearchParams'

import MyAccountWrapper from './my-account-wrapper'
import MyAccountContent from './my-account-content'

const MyAccountLayout = ({ children }) => {
  const { hasSearchParams } = useSearchParams()

  return (
    <MyAccountWrapper hasSearchParams={hasSearchParams}>
      <MyAccountContent>
        {children}
      </MyAccountContent>
    </MyAccountWrapper>
  )
}

MyAccountLayout.propTypes = {
  children: PropTypes.element
}

MyAccountLayout.defaultProps = {
  children: null
}

export default MyAccountLayout
